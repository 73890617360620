//import default
import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";

//api
import api from "service";

//context
import { ShowPage } from "context/showPage";
import { Context } from "context/data";

//Tema
import Theme from "theme";

//import global
import Input from "components/web/input";
import Select from "components/web/select";
import Button from "components/web/button";
import AnimationText from "components/web/animationText";
import CardInfo from "components/web/cardInfo";
import Navbar from "components/web/navbar";

//images
// import OctalLogo from "./midia/images/8D/logo.svg";
import HortaIcon from "assets/images/octal/logo/horta.svg";
import IconDesign from "assets/images/octal/menu/icon-design.svg";
import IconCode from "assets/images/octal/menu/icon-code.svg";

const PageID = "Horta";

export default ({ viewMode, ContentShow }) => {
  const { PageInfo, GoPage, GoInitPage } = ShowPage();
  const { Data, Set } = Context();
  return {
    render: (
      <Fade left>
        {Render({
          component: "container",
          style: {
            display: ContentShow === PageID ? "flex" : "none",
            flexDirection: viewMode === "desktop" ? "row" : "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
            padding: 12,
            gap: 12,
          },
          children: [
            //image
            {
              component: "container",
              style: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: 8,
              },
              children: [
                //image
                {
                  component: "container",
                  style: {
                    display: viewMode === "desktop" ? "flex" : "none",
                    width: "16vw",
                    height: "16vw",
                    aspectRatio: "1/1",
                    borderRadius: "50%",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#FFE5C2",
                  },
                  children: [
                    {
                      component: "image",
                      src: HortaIcon,
                      style: {
                        width: "92%",
                        height: "92%",
                        alignItems: "center",
                        aspectRatio: "1/1",
                      },
                    },
                  ],
                },
                //linkSite
                {
                  component: "text",
                  text: "www.dahortaprahorta.com",
                  style: {
                    fontStyle: "italic",
                    fontSize: 14,
                    fontFamily: "Poppins",
                    color: "#B5B5B5",
                    textDecoration: "underline",
                  },
                },
              ],
            },

            //textComponents
            {
              component: "container",
              style: {
                display: "flex",
                width: "100%",
                height: "100%",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                marginLeft: 32,
                paddingTop: viewMode === "desktop" ? "8%" : 0,
                gap: 8,
              },
              children: [
                //anoDev
                {
                  component: "text",
                  text: "2019 - 2021",
                  style: {
                    width: "auto",
                    color: "#B5B5B5",
                    fontFamily: "Poppins",
                  },
                },
                //NameProject
                {
                  component: "text",
                  text: "Da Horta pra Horta",
                  style: {
                    width: "auto",
                    color: "white",
                    fontWeight: "bold",
                    fontSize: 32,
                    fontFamily: "Poppins",
                    marginTop: 8,
                  },
                },
                //Description
                {
                  component: "text",
                  text: "Em um ano e meio, com a primeira versão da biblioteca desenvolvemos do zero o projeto de hortifrut no CEAGESP (da horda).",
                  style: {
                    width: "90%",
                    color: "#B5B5B5",
                    fontFamily: "Poppins",
                    marginTop: 4,
                  },
                },
                //Equipe
                {
                  component: "text",
                  text: "Equipe",
                  style: {
                    width: "auto",
                    color: "white",
                    fontWeight: "bold",
                    fontSize: 18,
                    fontFamily: "Poppins",
                    marginTop: 8,
                  },
                },
                //contentEquipe
                {
                  component: "container",
                  style: {
                    display: "flex",
                    flexDirection: "column",
                    gap: 4,
                  },
                  children: [
                    //iconDesign
                    {
                      component: "container",
                      style: {
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      },
                      children: [
                        {
                          component: "image",
                          src: IconDesign,
                        },
                        //anoDev
                        {
                          component: "text",
                          text: "02 Designers",
                          style: {
                            width: "auto",
                            color: "#B5B5B5",
                            fontFamily: "Poppins",
                          },
                        },
                      ],
                    },
                    //iconDev
                    {
                      component: "container",
                      style: {
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      },
                      children: [
                        {
                          component: "image",
                          src: IconCode,
                        },
                        //anoDev
                        {
                          component: "text",
                          text: "05 Desenvolvedores",
                          style: {
                            width: "auto",
                            color: "#B5B5B5",
                            fontFamily: "Poppins",
                          },
                        },
                      ],
                    },
                  ],
                },
                //textDestaque
                {
                  component: "container",
                  style: {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 12,
                  },
                  children: [
                    {
                      component: "text",
                      text: "4 WEB",
                      style: {
                        padding: 8,
                        borderRadius: 24,
                        color: "black",
                        backgroundColor: "#00FFC3",
                        fontFamily: "Poppins",
                      },
                    },
                    {
                      component: "text",
                      text: "2 APP",
                      style: {
                        padding: 8,
                        borderRadius: 24,
                        color: "black",
                        backgroundColor: "#00FFC3",
                        fontFamily: "Poppins",
                      },
                    },
                    {
                      component: "text",
                      text: "2 BI",
                      style: {
                        padding: 8,
                        borderRadius: 24,
                        color: "black",
                        backgroundColor: "#00FFC3",
                        fontFamily: "Poppins",
                      },
                    },
                  ],
                },
              ],
            },
          ],
        })}
      </Fade>
    ),
  };
};
