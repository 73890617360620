import Theme from "theme";
import React, { useState, useEffect } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";
import { ShowPopup } from "context/popup";
import Column from "antd/es/table/Column";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export default (opt) => {
  const {
    PopupShow,
    PopupClose,
    PopupStatus,
    PopupInfo,
    PopupType,
    PopupTime,
  } = ShowPopup();

  useEffect(async () => {
    if (PopupType === "normal") return;
    await delay(PopupTime);
    if (PopupStatus === true) PopupClose();

    // fazer logica para nao fechar os popup qund clica no X
  }, [PopupStatus]);

  const [stylePopup, setStylePopup] = useState({
    bcg: {},
    box: {
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
    },
    close: {
      marginRight: "10px",
      padding: "8px 16px",
      backgroundColor: "lightgray",
      color: "black",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
    title: {
      fontSize: "24px",
      fontWeight: "bold",
      marginBottom: "10px",
    },
    description: {
      marginBottom: "20px",
    },
    onConfirm: {
      marginRight: "10px",
      padding: "8px 16px",
      backgroundColor: "green",
      color: "white",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
    onCancel: {
      padding: "8px 16px",
      backgroundColor: "red",
      color: "white",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
  });

  return {
    render: (
      <Fade>
        <div
          style={{
            display: PopupStatus && PopupType === "normal" ? "flex" : "none",
            overflowY: "hidden",
            width: "100%",
            minWidth: "100%",
            maxWidth: "100%",
            height: "100%",
            minHeight: "100%",
            maxHeight: "100%",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 9999,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <Fade in>
            {Render({
              component: "container",
              style: {
                display: PopupStatus ? "flex" : "none",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                alignItems: "center",
                justifyContent: "center",
                ...stylePopup.bcg,
                ...PopupInfo.style?.bcg,
              },
              children: [
                {
                  component: "container",
                  style: {
                    width: "25%",
                    height: "auto",
                    backgroundColor: "white",
                    alignItems: "center",
                    borderRadius: "10px",
                    padding: 0,
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
                    ...stylePopup.box,
                    ...PopupInfo.style?.box,
                  },
                  children: [
                    // title + box close
                    {
                      component: "container",
                      style: {
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: 4,
                      },
                      children: [
                        // title
                        {
                          component: "text",
                          text: PopupInfo.title || "",
                          style: {
                            fontWeight: "bold",
                            fontFamily: Theme.fontFamilies.primary,
                            ...stylePopup.title,
                            ...PopupInfo.style?.text,
                          },
                        },

                        // close
                        {
                          component: "container",
                          text: PopupInfo.closeText || "X",
                          style: {
                            marginRight: "10px",
                            ...stylePopup.close,
                            ...PopupInfo.style?.close,
                          },
                          onClick: async (e) => {
                            PopupClose();
                            try {
                              await PopupInfo.onClose();
                            } catch (err) {
                              // console.log(err);
                            }
                          },
                        },
                      ],
                    },
                    //Descrição
                    {
                      component: "text",
                      text: PopupInfo.description || "",
                      style: {
                        display: "flex",
                        marginBottom: 8,
                        fontFamily: Theme.fontFamilies.primary,
                        ...stylePopup.description,
                        ...PopupInfo.style?.description,
                      },
                    },
                    // content
                    {
                      component: "container",
                      style: {
                        display: PopupInfo.item?.length > 0 ? "flex" : "none",
                        flex: 1,
                      },
                      children: PopupInfo.item || [],
                    },

                    // buttons acttions
                    {
                      component: "container",
                      style: {
                        width: "100%",
                        display: "flex",
                        marginTop: 8,
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      },
                      children: PopupInfo.buttons || [
                        //cancelar
                        {
                          component: "container",
                          text: PopupInfo.cancelText || "Cancelar",
                          style: {
                            marginRight: 8,
                            fontFamily: Theme.fontFamilies.primary,
                            ...stylePopup.onCancel,
                            display:
                              PopupInfo.cancelText?.length > 0
                                ? "flex"
                                : "none",
                          },
                          onClick: async (e) => {
                            PopupClose();
                            try {
                              await PopupInfo.onCancel();
                            } catch (err) {
                              console.log(err);
                            }
                          },
                        },
                        //confirmar
                        {
                          component: "container",
                          text: PopupInfo.confirmText || "Confirmar",
                          style: {
                            marginLeft: "15px",
                            fontFamily: Theme.fontFamilies.primary,
                            ...stylePopup.onConfirm,
                            display:
                              PopupInfo.confirmText?.length > 0
                                ? "flex"
                                : "none",
                          },
                          onClick: async (e) => {
                            PopupClose();
                            try {
                              await PopupInfo.onConfirm();
                            } catch (err) {
                              console.log(err);
                            }
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            })}
          </Fade>
        </div>

        {/* Type Timer */}
        <div
          style={{
            display: PopupStatus && PopupType === "time" ? "flex" : "none",
            overflowY: "hidden",
            position: "fixed",
            backgroundColor: "rgba(255, 255, 255, 1)",
            flexDirection: "column",
            overflow: "hidden",
            // height: "100%",
            // faz lista e adicionar quando estiver ativo
            // colocar central de notificacol como um icone..
            // colocar botao pra limpar historico...
            alignItems: "flex-end",
            justifyContent: "flex-start",
            zIndex: 9999,
            bottom: 32,
            left: 16,
          }}
        >
          <Fade left>
            {Render({
              component: "container",
              style: {
                display: PopupStatus ? "flex" : "none",
                width: 300,
                height: "auto",
                backgroundColor: "rgba(255, 255, 255, 1)",
                alignItems: "flex-end",
                justifyContent: "flex-start",
                ...stylePopup.bcg,
                zIndex: 9999,
              },
              children: [
                {
                  component: "container",
                  style: {
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "10px",
                    padding: "20px",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.24)",
                    ...stylePopup.box,
                  },
                  children: [
                    // title + box close
                    {
                      component: "container",
                      style: {
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      },
                      children: [
                        // title
                        {
                          component: "text",
                          text: PopupInfo.title || "Title",
                          style: {
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "10px",
                            fontFamily: Theme.fontFamilies.primary,
                            ...stylePopup.title,
                          },
                        },

                        // close

                        {
                          component: "container",
                          text: PopupInfo.closeText || "X",
                          style: {
                            marginRight: "10px",
                            ...stylePopup.close,
                          },
                          onClick: async (e) => {
                            PopupClose();
                            try {
                              await PopupInfo.onClose();
                            } catch (err) {
                              console.log(err);
                            }
                          },
                        },
                      ],
                    },

                    {
                      component: "text",
                      text: PopupInfo.description || "",
                      style: {
                        marginBottom: "20px",
                        fontFamily: Theme.fontFamilies.primary,
                        ...stylePopup.description,
                      },
                    },

                    // buttons acttions
                    {
                      component: "container",
                      style: {
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      },
                      children: PopupInfo.buttons || [
                        {
                          component: "container",
                          text: PopupInfo.cancelText || "Cancelar",
                          style: {
                            marginRight: "10px",
                            ...stylePopup.onCancel,
                            fontFamily: Theme.fontFamilies.primary,
                            display:
                              PopupInfo.cancelText?.length > 0
                                ? "flex"
                                : "none",
                          },
                          onClick: async (e) => {
                            PopupClose();
                            try {
                              await PopupInfo.onCancel();
                            } catch (err) {
                              console.log(err);
                            }
                          },
                        },
                        {
                          component: "container",
                          text: PopupInfo.confirmText || "Confirmar",
                          style: {
                            marginLeft: "15px",
                            ...stylePopup.onConfirm,
                            fontFamily: Theme.fontFamilies.primary,
                            display:
                              PopupInfo.confirmText?.length > 0
                                ? "flex"
                                : "none",
                          },
                          onClick: async (e) => {
                            PopupClose();
                            try {
                              await PopupInfo.onConfirm();
                            } catch (err) {
                              console.log(err);
                            }
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            })}
          </Fade>
        </div>
      </Fade>
    ),
  };
};
