//default imports
import { Render } from "@8_dev/sjson-web";
import { useEffect, useState } from "react";
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";

//components
import Navbar from "components/web/navbar";
import Button from "components/web/button";

import Theme from "theme";

//pages
import Content from "../page";
import WorkTeam from "../workTeam";
import About from "../about";
import Client from "../client";

//images
// import LogoOctal from "assets/images/octal/logo/logoExpand.svg";
import LogoOctal from "assets/images/octal/logo/logo.svg";
import MenuOption from "assets/images/octal/menu/menu-option.svg";
import Close from "assets/images/octal/menu/close.svg";

export default ({ viewMode }) => {
  const { PageInfo, GoPage, GoInitPage } = ShowPage();
  const { PopupShow, PopupClose } = ShowPopup();

  const [isClicked, setIsClicked] = useState(null);

  const [ContentPage, setContentPage] = useState("");

  const ContentParam = {
    viewMode,
    ContentPage,
    setContentPage,
  };

  useEffect(async () => {
    setContentPage("PageFull");
  }, []);

  // let Pages = [];
  const Nav = {
    component: "container",
    style: {
      width: "100%",
      minHeight: "8%",
      maxHeight: "8%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: 12,
      top: 0,
      zIndex: 999,
      position: viewMode === "desktop" ? "static" : "fixed",
    },
    children: [
      //logo
      {
        component: "container",
        style: {
          display: "flex",
          height: "6vh",
          width: "12vw",
        },
        children: [
          {
            component: "image",
            src: LogoOctal,
            style: {
              display: "flex",
              height: "100%",
              cursor: "pointer",
            },
            onClick: () => {
              setContentPage("PageFull");
            },
          },
        ],
      },
      //links
      {
        component: "container",
        style: {
          display: viewMode === "desktop" ? "flex" : "none",
          height: "100%",
          flexDirection: "row",
          gap: "1vw",
          alignItems: "center",
          justifyContent: "center",
        },
        children: [
          //clients
          {
            component: "text",
            text: "Clientes",
            onClick: () => {
              setContentPage("ClientsPage");
              setIsClicked("ClientsPage");
              setTimeout(() => {
                setIsClicked(null);
              }, 200); // Remove a string após 200ms
            },
            style: {
              fontSize: 14,
              padding: 12,
              borderRadius: 4,
              width: "auto",
              fontFamily: "Poppins",
              color: isClicked === "ClientsPage" ? "white" : "#757575",
              cursor: "pointer",
              backgroundColor:
                isClicked === "ClientsPage" ? "black" : "transparent",
              transition: "background-color 0.3s ease",
            },
          },
          //sobre nós
          {
            component: "text",
            text: "Sobre nós",
            style: {
              fontSize: 14,
              padding: 12,
              borderRadius: 4,
              width: "auto",
              fontFamily: "Poppins",
              color: isClicked === "AboutUsPage" ? "white" : "#757575",
              backgroundColor:
                isClicked === "AboutUsPage" ? "black" : "transparent",
              cursor: "pointer",
              transition: "background-color 0.3s ease",
            },
            onClick: () => {
              setContentPage("AboutUsPage");
              setIsClicked("AboutUsPage");
              setTimeout(() => {
                setIsClicked(null);
              }, 200); // Remove a string após 200ms
            },
          },
          //trabalhe conosco
          {
            component: "text",
            text: "Trabalhe conosco",
            style: {
              fontSize: 14,
              padding: 12,
              borderRadius: 4,
              width: "auto",
              fontFamily: "Poppins",
              color: isClicked === "WorkPage" ? "white" : "#757575",
              backgroundColor:
                isClicked === "WorkPage" ? "black" : "transparent",
              cursor: "pointer",
              transition: "background-color 0.3s ease",
            },
            onClick: () => {
              setContentPage("WorkPage");
              setIsClicked("WorkPage");
              setTimeout(() => {
                setIsClicked(null);
              }, 200); // Remove a string após 200ms
            },
          },
        ],
      },
      //btn acess (Mobile none)
      {
        component: "container",
        style: {
          display: viewMode === "desktop" ? "flex" : "none",
          width: "12vw",
          minWidth: "18vh",
          height: "100%",
          padding: 4,
        },
        children: [
          Button({
            style: {
              button: {
                width: "100%",
                height: "100%",
                borderColor: "#211E40",
                border: "1px solid",
                borderRadius: 8,
              },
              label: {
                fontSize: "1.66vh",
                fontFamily: "Poppins",
                color: "#211E40",
              },
            },
            label: "Acessar sistema",
            onPress: async () => {
              window.location.href = "https://web-client.8d.octal.tec.br/";
            },
          }),
        ],
      },
      //btnMenuOption (mobile flex)
      {
        component: "image",
        src: MenuOption,
        style: {
          display: viewMode === "desktop" ? "none" : "flex",
          width: 28,
          height: 28,
        },
        onClick: () => {
          PopupShow({
            style: {
              box: {
                width: "60%",
                alignItems: "center",
                justifyContent: "center",
                padding: 0,
              },
              bcg: {
                alignItems: "flex-start",
                justifyContent: "flex-end",
                padding: 12,
              },
              close: {
                display: "none",
              },
              text: {
                display: "none",
              },
              description: {
                display: "none",
              },
            },
            item: [
              {
                component: "container",
                style: {
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: 8,
                },
                children: [
                  //Logo close
                  {
                    component: "container",
                    style: {
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                      paddingRight: 4,
                      height: "auto",
                    },
                    children: [
                      {
                        component: "image",
                        src: Close,
                        style: {
                          width: 24,
                          height: 24,
                          cursor: "pointer",
                        },
                        onClick: () => {
                          PopupClose();
                        },
                      },
                    ],
                  },
                  //links
                  {
                    component: "container",
                    style: {
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      flexDirection: "column",
                      gap: "1vw",
                      alignItems: "center",
                      justifyContent: "center",
                    },
                    children: [
                      //clientes
                      {
                        component: "container",
                        style: {
                          width: "100%",
                          borderBottom: "1px solid #757575",
                        },
                        children: [
                          Button({
                            style: {
                              button: {
                                width: "100%",
                                fontSize: 14,
                                padding: 12,
                                border: "none",
                                borderRadius: 4,
                                fontFamily: "Poppins",
                                color:
                                  isClicked === "ClientsPage"
                                    ? "white"
                                    : "#757575",
                                cursor: "pointer",
                                backgroundColor:
                                  isClicked === "ClientsPage"
                                    ? "black"
                                    : "transparent",
                                transition: "background-color 0.3s ease",
                              },
                              label: {
                                fontSize: 16,
                                fontFamily: "Poppins",
                                // color: "#211E40",
                              },
                            },
                            label: "Clientes",
                            onPress: async () => {
                              setContentPage("ClientsPage");
                              setIsClicked("ClientsPage");
                              setTimeout(() => {
                                setIsClicked(null);
                              }, 200); // Remove a string após 200ms
                              PopupClose();
                            },
                          }),
                        ],
                      },
                      //sobre nós
                      {
                        component: "container",
                        style: {
                          width: "100%",
                          borderBottom: "1px solid #757575",
                        },
                        children: [
                          Button({
                            style: {
                              button: {
                                width: "100%",
                                fontSize: 14,
                                padding: 12,
                                border: "none",
                                borderRadius: 4,
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                fontFamily: "Poppins",
                                color:
                                  isClicked === "AboutUsPage"
                                    ? "white"
                                    : "#757575",
                                cursor: "pointer",
                                backgroundColor:
                                  isClicked === "AboutUsPage"
                                    ? "black"
                                    : "transparent",
                                transition: "background-color 0.3s ease",
                              },
                              label: {
                                fontSize: 16,
                                fontFamily: "Poppins",
                                textAlign: "flex-start",
                                // color: "#211E40",
                              },
                            },
                            label: "Sobre nós",
                            onPress: async () => {
                              setContentPage("AboutUsPage");
                              setIsClicked("AboutUsPage");
                              setTimeout(() => {
                                setIsClicked(null);
                              }, 200); // Remove a string após 200ms
                              PopupClose();
                            },
                          }),
                        ],
                      },
                      //trabalhe conosco
                      {
                        component: "container",
                        style: {
                          width: "100%",
                          borderBottom: "1px solid #757575",
                        },
                        children: [
                          Button({
                            style: {
                              button: {
                                width: "100%",
                                fontSize: 14,
                                padding: 12,
                                border: "none",
                                borderRadius: 4,
                                fontFamily: "Poppins",
                                color:
                                  isClicked === "WorkPage"
                                    ? "white"
                                    : "#757575",
                                cursor: "pointer",
                                backgroundColor:
                                  isClicked === "WorkPage"
                                    ? "black"
                                    : "transparent",
                                transition: "background-color 0.3s ease",
                              },
                              label: {
                                fontSize: 16,
                                fontFamily: "Poppins",
                                // color: "#211E40",
                              },
                            },
                            label: "Trabalhe conosco",
                            onPress: async () => {
                              setContentPage("WorkPage");
                              setIsClicked("WorkPage");
                              setTimeout(() => {
                                setIsClicked(null);
                              }, 200); // Remove a string após 200ms
                              PopupClose();
                            },
                          }),
                        ],
                      },
                      //acessar sistema
                      {
                        component: "container",
                        style: {
                          width: "100%",
                          borderBottom: "1px solid #757575",
                        },
                        children: [
                          Button({
                            style: {
                              button: {
                                width: "100%",
                                fontSize: 14,
                                padding: 12,
                                border: "none",
                                borderRadius: 4,
                                fontFamily: "Poppins",
                                color:
                                  isClicked === "ClientsPage"
                                    ? "white"
                                    : "#757575",
                                cursor: "pointer",
                                backgroundColor:
                                  isClicked === "ClientsPage"
                                    ? "black"
                                    : "transparent",
                                transition: "background-color 0.3s ease",
                              },
                              label: {
                                fontSize: 16,
                                fontFamily: "Poppins",
                                // color: "#211E40",
                              },
                            },
                            label: "Acessar sistema",
                            onPress: async () => {
                              window.location.href =
                                "https://web-client.8d.octal.tec.br/";
                            },
                          }),
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          });
        },
      },
    ],
  };

  const PageStyle = {
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    height: viewMode === "desktop" ? "100vh" : "100%",
    overflowY: "hidden",
    overflowX: "hidden",
  };

  const Pages = [
    Nav,
    {
      component: "container",
      style: {
        display: "flex",
        width: "100%",
        height: "92%",
        overflowY: "hidden",
        overflowX: "hidden",
      },
      children: [
        ...Content(ContentParam),
        ...About(ContentParam),
        ...WorkTeam(ContentParam),
        ...Client(ContentParam),
      ],
    },
  ];
  return {
    component: "container",
    style: PageStyle,
    children: Pages,
  };
};
