//import default
import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";

//api
import api from "service";

//context
import { ShowPage } from "context/showPage";
import { Context } from "context/data";

//Tema
import Theme from "theme";

const PageId = "WorkPage";

export default ({ viewMode, ContentPage, setContentPage }) => {
  const { PageInfo, GoPage, GoInitPage } = ShowPage();
  const { Data, Set } = Context();

  // useEffect(async () => {
  // setContentShow("PetinWeb")
  // }, []);

  const customScrollbarStyle = `
    ::-webkit-scrollbar {
      width: 8px;
      height: 4px;
    }
    ::-webkit-scrollbar-track {
      background: transparent; 
    }
    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  `;

  return {
    render: (
      <Fade>
        <style>{customScrollbarStyle}</style>
        {Render({
          component: "container",
          style: {
            display: ContentPage === PageId ? "flex" : "none",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            height: "100vh",
            width: "100vw",
            overflowY: "auto",
            overflowX: "hidden",
            backgroundColor: "gray",
          },
          children: [],
        })}
      </Fade>
    ),
  };
};
