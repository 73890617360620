//default imports
import { Render } from "@8_dev/sjson-web";
import Theme from "theme";
import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";

//Context
import { ShowPage } from "context/showPage";

//components
// import PopUp from "/components/web/popup";

// Assets dessa pagina

// Componente Global -> /lib/global/components/web/button.
// Componente Web -> /lib/web/components/web/button.
import PopUp from "components/web/popup";

// Componente Local -> /lib/local/components/web/button.

import Intro from "pages/intro";

let Page = () => {
  // const { Data, Set } = Context();
  const Navigation = ShowPage();

  const [viewMode, setViewMode] = useState("desktop");

  useEffect(async () => {
    const { innerWidth: window_width, innerHeight: window_height } = window;
    const proportion = window_width / window_height;
    if (proportion < 1) setViewMode("mobile");
    else setViewMode("desktop");
    function handleResize() {
      const { innerWidth: window_width, innerHeight: window_height } = window;
      const proportion = window_width / window_height;
      if (proportion < 1) setViewMode("mobile");
      else setViewMode("desktop");
    }
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const contentParam = {
    viewMode,
  };

  return Render({
    component: "container",
    style: {
      display: "flex",
      width: "100%",
      height: "100%",
    },
    children: [
      PopUp(),
      {
        render: (
          <Fade>
            {Render({
              component: "container",
              style: {
                display: "flex",
                width: "100%",
                height: "100%",
              },
              children: [Intro(contentParam)],
            })}
          </Fade>
        ),
      },
    ],
  });
};

export default Page;
