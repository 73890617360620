//import default
import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";

//api
import api from "service";

//context
import { ShowPage } from "context/showPage";
import { Context } from "context/data";

//Tema
import Theme from "theme";

//import global
import Input from "components/web/input";
import Select from "components/web/select";
import Button from "components/web/button";
import AnimationText from "components/web/animationText";
import CardInfo from "components/web/cardInfo";
import CardView from "components/web/cardView";

//images
// import OctalLogo from "./midia/images/8D/logo.svg";

//pages
import Petin from "./projects/petin";
import Horta from "./projects/horta";
import Dream from "./projects/dream";

import HortaIcon from "assets/images/octal/menu/icon-horta.svg";
import PetinIcon from "assets/images/octal/menu/icon-petin.svg";
import DreamIcon from "assets/images/octal/menu/icon-dream.svg";
import LogoOctal from "assets/images/octal/logo/iconOctal.png";

const PageId = "PageFull";

export default ({ viewMode, ContentPage, setContentPage }) => {
  const { PageInfo, GoPage, GoInitPage } = ShowPage();
  const { Data, Set } = Context();

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  // useEffect(async () => {
  // setContentShow("PetinWeb")
  // }, []);

  const [ContentShow, setContentShow] = useState("Petin");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [user, setTypeUser] = useState([
    {
      _id: 1,
      name: "Dev",
    },
    {
      _id: 2,
      name: "Cliente",
    },
  ]);

  const handleSelectChange = (selectedItem) => {
    setTypeUser(selectedItem);
  };

  const inputStyle = {
    select: {
      width: "100%",
      height: 48,
      border: "1px solid #bfbfbf",
      backgroundColor: "transparent",
      borderRadius: 8,
      paddingLeft: 12,
      fontFamily: "Poppins",
      marginBottom: 4,
    },
    input: {
      width: "100%",
      height: 48,
      border: "1px solid #bfbfbf",
      borderRadius: 8,
      paddingLeft: 14,
      fontFamily: "Poppins",
    },
    title: {
      fontFamily: "Poppins",
      fontSize: "1.2vh",
      marginLeft: 4,
    },
  };

  const typeUser = Select({
    title: "Quem é você ?",
    item: user,
    onChange: handleSelectChange,
    style: {
      ...inputStyle,
    },
  });

  const inputName = Input({
    title: "Nome completo",
    value: name,
    setValue: setName,
    // mask: "99.999.999/9999-99",
    style: {
      ...inputStyle,
    },
  });

  const inputEmail = Input({
    title: "Email",
    value: email,
    setValue: setEmail,
    style: {
      ...inputStyle,
    },
  });

  const inputPhone = Input({
    title: "Telefone (opcional)",
    mask: "(99) 99999-9999",
    value: phone,
    setValue: setPhone,
    style: {
      ...inputStyle,
    },
  });

  const inputMessage = Input({
    title: "Deixe-nos uma mensagem",
    value: message,
    setValue: setMessage,
    style: {
      ...inputStyle,
      input: {
        height: "12vh",
        border: "1px solid #bfbfbf",
        borderRadius: 8,
        paddingLeft: 12,
        fontFamily: "Poppins",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      },
    },
  });

  const customScrollbarStyle = `
    ::-webkit-scrollbar {
      width: 8px;
      height: 4px;
    }
    ::-webkit-scrollbar-track {
      background: transparent; 
    }
    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  `;

  return {
    render: (
      <Fade>
        <style>{customScrollbarStyle}</style>
        {Render({
          component: "container",
          style: {
            display: ContentPage === PageId ? "flex" : "none",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            height: "100%",
            width: "100%",
            overflowY: "auto",
            overflowX: "hidden",
          },
          children: [
            //INTRO
            {
              component: "container",
              style: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: viewMode === "desktop" ? "flex-end" : "center",
                width: "100%",
                minHeight: viewMode === "desktop" ? "82%" : "68vh",
                maxHeight: viewMode === "desktop" ? "82%" : "68vh",
                background:
                  "linear-gradient(to top, #80A7CD 2%, #BFD3E6 10%, #FFFFFF 20%)",
              },
              children: [
                {
                  component: "container",
                  style: {
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 24,
                  },
                  children: [
                    //textIntro
                    {
                      component: "container",
                      style: {
                        display: "flex",
                        width: "100%",
                        height: "auto",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: 24,
                      },
                      children: [
                        //slogan
                        {
                          component: "container",
                          style: {
                            display: "flex",
                            width: "100%",
                            minHeight: viewMode === "desktop" ? "6vh" : "4vh",
                            maxHeight: viewMode === "desktop" ? "6vh" : "4vh",
                            justifyContent: "center",
                            alignItems: "center",
                          },
                          children: [
                            {
                              component: "text",
                              text: (
                                <AnimationText
                                  phrases={[
                                    "</OctalDev",
                                    "desenvolvendo o futuro/>",
                                  ]}
                                  style={{
                                    fontSize: viewMode === "desktop" ? 48 : 28,
                                  }}
                                />
                              ),
                            },
                          ],
                        },
                        //textFinish
                        {
                          component: "container",
                          style: {
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: 4,
                          },
                          children: [
                            {
                              component: "text",
                              text:
                                viewMode === "desktop"
                                  ? "A Octal Dev tem como objetivo a criação e o desenvolvimento de sistemas para atender"
                                  : "A Octal Dev tem como objetivo a criação e o desenvolvimento de sistemas para atender empresas e startups.",
                              style: {
                                width: "100%",
                                textAlign: "center",
                                fontSize:
                                  viewMode === "desktop" ? 22 : "1.88vh",
                                fontFamily: "Poppins",
                                color: "#6A6A6A",
                                padding: 2,
                                lineHeight: 1.2,
                              },
                            },
                            {
                              component: "container",
                              style: {
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: 4,
                              },
                              children: [
                                {
                                  component: "text",
                                  text: "empresas e startups. ",
                                  style: {
                                    display:
                                      viewMode === "desktop" ? "flex" : "none",
                                    fontSize:
                                      viewMode === "desktop" ? 22 : "1.88vh",
                                    fontFamily: "Poppins",
                                    color: "#6A6A6A",
                                  },
                                },
                                {
                                  component: "text",
                                  text: " Transformando ideias em realidade.",
                                  style: {
                                    fontSize:
                                      viewMode === "desktop" ? 22 : "1.88vh",
                                    fontFamily: "Poppins",
                                    color: "#134B82",
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    //btn fale com um especialista
                    {
                      component: "container",
                      style: {
                        width: "auto",
                        height: "6vh",
                        padding: 4,
                      },
                      children: [
                        Button({
                          style: {
                            button: {
                              width: "100%",
                              height: "100%",
                              borderColor: "#211E40",
                              border: "1px solid",
                              borderRadius: 8,
                            },
                            label: {
                              fontSize: "1.66vh",
                              fontFamily: "Poppins",
                              color: "#211E40",
                            },
                          },
                          label: "Fale com um especialista ->",
                          onPress: async () => {
                            alert("olá");
                          },
                        }),
                      ],
                    },
                  ],
                },
              ],
            },
            //PROJETOS
            {
              component: "container",
              style: {
                display: "flex",
                alignItems: "center",
                minHeight: viewMode === "desktop" ? "82%" : "82vh",
                maxHeight: viewMode === "desktop" ? "82%" : "82vh",
                flexDirection: "column",
                width: "100%",
                background:
                  "linear-gradient(to bottom, #80A7CD 2%, #BFD3E6 4%, #FFFFFF 20%)",
              },
              children: [
                {
                  component: "container",
                  style: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: viewMode === "desktop" ? "82%" : "98%",
                    minHeight: viewMode === "desktop" ? "64vh" : "92%",
                    borderRadius: 12,
                    padding: 12,
                    backgroundColor: "#5D5A87",
                  },
                  children: [
                    {
                      component: "container",
                      style: {
                        display: "flex",
                        flexDirection:
                          viewMode === "desktop" ? "row" : "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                        paddingLeft: viewMode === "desktop" ? "8%" : 0,
                        gap: 12,
                      },
                      children: [
                        //navBar
                        {
                          component: "container",
                          style: {
                            display: "flex",
                            height: viewMode === "desktop" ? "100%" : "20%",
                            width: viewMode === "desktop" ? "30%" : "100%",
                            // border: "1px solid black",
                          },
                          children: [
                            CardView({
                              infoIntro: "Petin",
                              style: {
                                boxMain: {
                                  flexDirection:
                                    viewMode === "desktop" ? "column" : "row",
                                },
                              },
                              item: [
                                //PETIN
                                {
                                  page: "Petin",
                                  title: "Petin digital",
                                  iconSize: "100%",
                                  image: {
                                    src: PetinIcon,
                                  },
                                  style: {
                                    iconBox: {
                                      width:
                                        viewMode === "desktop"
                                          ? "100%"
                                          : "auto",
                                    },
                                    title: {
                                      display:
                                        viewMode === "desktop"
                                          ? "flex"
                                          : "none",
                                    },
                                  },
                                  onClick: (page) => {
                                    setContentShow(page);
                                  },
                                },
                                //HORTA
                                {
                                  page: "Horta",
                                  title: "Da hora pra horta",
                                  iconSize: "100%",
                                  image: {
                                    src: HortaIcon,
                                  },
                                  style: {
                                    iconBox: {
                                      width:
                                        viewMode === "desktop"
                                          ? "100%"
                                          : "auto",
                                    },
                                    title: {
                                      display:
                                        viewMode === "desktop"
                                          ? "flex"
                                          : "none",
                                    },
                                  },
                                  onClick: (page) => {
                                    setContentShow(page);
                                  },
                                },
                                //DREAM
                                {
                                  page: "Dream",
                                  title: "Dream Buffets",
                                  iconSize: "100%",
                                  image: {
                                    src: DreamIcon,
                                  },
                                  style: {
                                    iconBox: {
                                      width:
                                        viewMode === "desktop"
                                          ? "100%"
                                          : "auto",
                                    },
                                    title: {
                                      display:
                                        viewMode === "desktop"
                                          ? "flex"
                                          : "none",
                                    },
                                  },
                                  onClick: (page) => {
                                    setContentShow(page);
                                  },
                                },
                              ],
                            }),
                          ],
                        },
                        //content
                        {
                          component: "container",
                          style: {
                            display: "flex",
                            height: "100%",
                            width: "100%",
                          },
                          children: [
                            Petin({ viewMode, ContentShow }),
                            Horta({ viewMode, ContentShow }),
                            Dream({ viewMode, ContentShow }),
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            //PQ A OCTAL ?
            {
              component: "container",
              style: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                minHeight: viewMode === "desktop" ? "82%" : "auto",
                maxHeight: viewMode === "desktop" ? "82%" : "auto",
              },
              children: [
                //text
                {
                  component: "container",
                  style: {
                    display: "flex",
                    width: "100%",
                    height: "auto",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 12,
                    marginBottom: 22,
                  },
                  children: [
                    //text diferencial
                    {
                      component: "text",
                      text: "Porquê somos diferentes?",
                      style: {
                        fontSize: viewMode === "desktop" ? "1.88vw" : "2.22vh",
                        fontFamily: "Poppins",
                        color: "#134B82",
                      },
                    },
                    //textInfo
                    {
                      component: "text",
                      text: "Nossa cultura gira em torno da transparência.",
                      style: {
                        fontSize: viewMode === "desktop" ? 18 : 12,
                        fontFamily: "Poppins",
                        color: "#6A6A6A",
                      },
                    },
                  ],
                },
                //content
                {
                  component: "container",
                  style: {
                    display: "flex",
                    height: "100%",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: viewMode === "desktop" ? "row" : "column",
                    padding: 12,
                    gap: 12,
                  },
                  children: [
                    //primeiro content
                    {
                      component: "container",
                      style: {
                        display: "flex",
                        minHeight: viewMode === "desktop" ? "62%" : "32vh",
                        width: "100%",
                        flexDirection: "row",
                        border: "1px solid black",
                      },
                    },
                    //segundo content
                    {
                      component: "container",
                      style: {
                        display: "flex",
                        minHeight: viewMode === "desktop" ? "62%" : "32vh",
                        width: "100%",
                        flexDirection: "row",
                        border: "1px solid black",
                      },
                    },
                    //terceiro content
                    {
                      component: "container",
                      style: {
                        display: "flex",
                        minHeight: viewMode === "desktop" ? "62%" : "32vh",
                        width: "100%",
                        flexDirection: "row",
                        border: "1px solid black",
                      },
                    },
                  ],
                },
              ],
            },
            //DEV PRA DEV
            {
              component: "container",
              style: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                height: "auto",
                marginTop: viewMode === "desktop" ? 0 : "14vh",
              },
              children: [
                //conteúdo
                {
                  component: "container",
                  style: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    width: "100%",
                    height: "auto",
                    padding: 12,
                    gap: 32,
                  },
                  children: [
                    //textTilte
                    {
                      component: "container",
                      style: {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 12,
                      },
                      children: [
                        //text dev para dev
                        {
                          component: "text",
                          text: "Dev para Dev",
                          style: {
                            fontSize:
                              viewMode === "desktop" ? "1.88vw" : "2.22vh",
                            fontFamily: "Poppins",
                            color: "#134B82",
                          },
                        },
                        //textDEV
                        {
                          component: "container",
                          style: {
                            display: "flex",
                            flexDirection:
                              viewMode === "desktop" ? "row" : "column",
                            alignItems: "center",
                            gap: 4,
                          },
                          children: [
                            {
                              component: "text",
                              text: "Inovamos nosso ambiente de desenvolvimento",
                              style: {
                                fontSize: viewMode === "desktop" ? 18 : 12,
                                fontFamily: "Poppins",
                                color: "#6A6A6A",
                              },
                            },
                            {
                              component: "text",
                              text: "pensando no dev.",
                              style: {
                                fontSize: viewMode === "desktop" ? 18 : 12,
                                fontFamily: "Poppins",
                                color: "#134B82",
                                textDecoration: "underline",
                              },
                            },
                          ],
                        },
                      ],
                    },
                    //cards
                    {
                      component: "container",
                      style: {
                        display: "flex",
                        width: viewMode === "desktop" ? "52%" : "100%",
                        height: "auto",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                      children: [
                        CardInfo({
                          item: [
                            //cultura de trabalho.
                            {
                              title: "Cultura de trabalho",
                              children: [
                                {
                                  description:
                                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                                },
                              ],
                            },
                            //desenvolvimento colaborativo.
                            {
                              title: "Desenvolvimento colaborativo",
                              children: [
                                {
                                  description:
                                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                                },
                              ],
                            },
                            //segurança no desenvolvimento.
                            {
                              title: "Segurança no desenvolvimento",
                              children: [
                                {
                                  description:
                                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                                },
                              ],
                            },
                            //acompanhamento em tempo real.
                            {
                              title: "Acompanhamento em tempo real",
                              children: [
                                {
                                  description:
                                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                                },
                              ],
                            },
                            //planejamento dinâmico.
                            {
                              title: "Planejamento dinâmico",
                              children: [
                                {
                                  description:
                                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                                },
                              ],
                            },
                          ],
                        }),
                      ],
                    },
                  ],
                },
              ],
            },
            //FALE CONOSCO
            {
              component: "container",
              style: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
                minHeight: "100%",
                maxHeight: "100%",
                marginTop: viewMode === "desktop" ? 0 : "12vh",
              },
              children: [
                //text fale conosco
                {
                  component: "text",
                  text: "Fale conosco",
                  style: {
                    marginLeft: 12,
                    fontSize: viewMode === "desktop" ? "1.88vw" : "2.22vh",
                    fontFamily: "Poppins",
                    color: "#134B82",
                    marginBottom: 12,
                  },
                },
                //text
                {
                  component: "container",
                  style: {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 4,
                    marginBottom: 12,
                  },
                  children: [
                    {
                      component: "text",
                      text: "Se identificou com seus objetivos?",
                      style: {
                        fontSize: viewMode === "desktop" ? 18 : 12,
                        fontFamily: "Poppins",
                        color: "#6A6A6A",
                      },
                    },
                    {
                      component: "text",
                      text: "a gente te ajuda!",
                      style: {
                        fontSize: viewMode === "desktop" ? 18 : 12,
                        fontFamily: "Poppins",
                        color: "#134B82",
                        textDecoration: "underline",
                      },
                    },
                  ],
                },
                //form
                {
                  component: "container",
                  style: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: viewMode === "desktop" ? "62vh" : "92%",
                    height: "auto",
                    padding: 12,
                    gap: 8,
                  },
                  children: [
                    inputName,
                    inputEmail,
                    inputPhone,
                    inputMessage,
                    //btnSend
                    {
                      component: "container",
                      style: {
                        width: "60%",
                        height: 64,
                        padding: 4,
                      },
                      children: [
                        Button({
                          style: {
                            button: {
                              width: "100%",
                              height: "100%",
                              backgroundColor: "#212121",
                              border: "none",
                              borderRadius: 8,
                            },
                            label: {
                              fontSize:
                                viewMode === "desktop" ? "0.8vw" : "1.44vh",
                              fontFamily: "Poppins",
                              color: "white",
                            },
                          },
                          label: "Enviar",
                          onPress: () => {
                            console.log(user, name, email, phone, message);
                          },
                        }),
                      ],
                    },
                  ],
                },
                //Footer
                {
                  component: "container",
                  style: {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "auto",
                    gap: 12,
                    padding: 12,
                    marginTop: 58,
                  },
                  children: [
                    //div
                    {
                      component: "container",
                      style: {
                        display: "flex",
                        backgroundColor: "#757575",
                        width: viewMode === "desktop" ? "42%" : "92%",
                        height: 1,
                      },
                    },
                    //content Footer
                    {
                      component: "container",
                      style: {
                        display: "flex",
                        // padding: 12,
                        width: viewMode === "desktop" ? "42%" : "92%",
                        height: "100%",
                        justifyContent: "space-between",
                        paddingLeft: viewMode === "desktop" ? 24 : 0,
                        paddingRight: viewMode === "desktop" ? 24 : 0,
                        alignItems: "center",
                        flexDirection: "row",
                      },
                      children: [
                        //logo
                        {
                          component: "image",
                          src: LogoOctal,
                          style: {
                            display: "flex",
                            width: viewMode === "desktop" ? 148 : "34vw",
                          },
                        },
                        //links
                        {
                          component: "container",
                          style: {
                            display: "flex",
                            width: "auto",
                            height: "auto",
                            gap: 24,
                          },
                          children: [
                            //links
                            {
                              component: "container",
                              style: {
                                display: "flex",
                                width: "auto",
                                height: "auto",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                                gap: 4,
                              },
                              children: [
                                {
                                  component: "text",
                                  text: "Links",
                                  style: {
                                    fontSize: 14,
                                    fontFamily: "Poppins",
                                  },
                                },
                                {
                                  component: "text",
                                  text: "Sobre nós",
                                  style: {
                                    fontSize: 12,
                                    color: "#6A6A6A",
                                    fontFamily: "Poppins",
                                  },
                                },
                                {
                                  component: "text",
                                  text: "Contato",
                                  style: {
                                    fontSize: 12,
                                    color: "#6A6A6A",
                                    fontFamily: "Poppins",
                                  },
                                },
                              ],
                            },
                            //Redes sociais
                            {
                              component: "container",
                              style: {
                                display: "flex",
                                width: "auto",
                                height: "auto",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                                gap: 4,
                              },
                              children: [
                                {
                                  component: "text",
                                  text: "Redes sociais",
                                  style: {
                                    fontSize: 14,
                                    fontFamily: "Poppins",
                                  },
                                },
                                {
                                  component: "text",
                                  text: "Instagram",
                                  style: {
                                    fontSize: 12,
                                    color: "#6A6A6A",
                                    fontFamily: "Poppins",
                                  },
                                },
                                {
                                  component: "text",
                                  text: "LinkedIn",
                                  style: {
                                    fontSize: 12,
                                    color: "#6A6A6A",
                                    fontFamily: "Poppins",
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    //div
                    {
                      component: "container",
                      style: {
                        display: "flex",
                        backgroundColor: "#757575",
                        width: viewMode === "desktop" ? "42%" : "92%",
                        height: 1,
                      },
                    },
                    //text
                    {
                      component: "text",
                      text: "© Todos os direitos reservados a OctalDev.",
                      style: {
                        fontStyle: "italic",
                        fontSize: viewMode === "desktop" ? "0.66vw" : "1vh",
                        fontFamily: "Poppins",
                        color: "#6A6A6A",
                      },
                    },
                  ],
                },
              ],
            },
          ],
        })}
      </Fade>
    ),
  };
};
